<template>
  <v-container class="cw-kreditz">
    <v-row
      align="start"
      class="cw-page__title"
    >
      <v-col
        class="pr-4"
        cols="auto"
      >
        <v-icon class="mt-1">
          person_outline
        </v-icon>
      </v-col>
      <v-col class="pl-0">
        <h1 class="text-h5">
          <translate>
            Verification of solvency
          </translate>
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="pt-1">
        <p>
          <translate>
            In order to provide you with a personal and fast loan offer, we use Kreditz to verify
            your solvency. The service enables responsible lending and usually takes less than 30
            seconds.
          </translate>
          <a
            id="cw-kreditz--info"
            class="ml-1"
            href="#"
            @click.prevent.stop="
              $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                component: 'DialogKreditz',
              });
              $eventLogger.clickEvent($event);
            "
          >
            <translate>More information</translate>
          </a>
        </p>

        <v-alert
          v-if="!mobile"
          border="left"
          type="warning"
          dense
          text
        >
          <translate>
            Antivirus protection may cause a problem when using Kreditz that prevents the connection
            to Kreditz. You can temporarily turn off the antivirus protection you are using to
            complete the Kreditz check.
          </translate>
        </v-alert>

        <v-alert
          v-if="isBusinessLoan && !isSoleTrader"
          border="left"
          type="info"
          dense
          text
        >
          <translate>
            In order to log in, please use your company's bank account and bank ID credentials.
            If you have any questions, you can contact our customer support.
          </translate>
        </v-alert>

        <v-alert
          v-if="!isBusinessLoan"
          border="left"
          type="info"
          dense
          text
        >
          <translate>
            Please select your main bank and identify yourself with your online banking ID.
            Please note, that the loan will also be transferred to this specific bank.
          </translate>
        </v-alert>

        <div class="cw-kreditz__wrapper">
          <v-slide-x-transition mode="out-in">
            <iframe
              v-if="
                !isBusinessLoan
                  || (isBusinessLoan && !isSoleTrader)
                  || (isBusinessLoan && isSoleTrader && selectedAccountType)
              "
              id="cw-kreditz__iframe"
              :key="selectedAccountType"
              :src="url"
              class="cw-kreditz__iframe"
            />
            <div v-else class="cw-kreditz__accountSelection">
              <p
                v-translate="{
                  phoneNumber: '06000&nbsp;55000',
                }"
                class="text-title mb-4"
                render-html="true"
              >
                Please select one of the following bank account types for the required
                solvency check of your company. If you have any questions, please contact our
                customer support at number %{ phoneNumber }.
              </p>

              <v-btn-toggle
                v-model="accountType"
                color="primary"
                class="transparent primary--text mb-8"
                active-class="primary white--text"
                dense
              >
                <v-btn
                  id="cw-kreditz__account-type--private"
                  class="flex-grow-1"
                  value="private"
                  @click="$eventLogger.clickEvent($event)"
                >
                  <translate>
                    Personal
                  </translate>
                </v-btn>

                <v-btn
                  id="cw-kreditz__account-type--business"
                  class="flex-grow-1"
                  value="business"
                  @click="$eventLogger.clickEvent($event)"
                >
                  <translate>
                    Business
                  </translate>
                </v-btn>
              </v-btn-toggle>
              <v-btn
                color="grey darken-4"
                :disabled="!accountType"
                :dark="!!accountType"
                @click="selectedAccountType = accountType"
              >
                <translate>Start the check</translate>
              </v-btn>
            </div>
          </v-slide-x-transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwKreditz',

  data: () => ({
    accountType: null,
    poller: null,
    selectedAccountType: null,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      mobile: 'getMobile',
      product: 'application/getProduct',
    }),

    isBusinessLoan() {
      return [
        'Saldo.Finland.SmePosInstalmentLoan',
        'Saldo.Finland.BusinessInstalmentLoan',
      ].includes(this.product.productName);
    },

    isSoleTrader() {
      return +this.initialData.isSoleTrader === 1;
    },

    url() {
      const url = new URL(process.env.VUE_APP_KREDITZ_SERVER_URL);

      const params = {
        client_id: '70',
        market: 'FI',
        locale: 'fi',
        is_iframe: 'true',
        type: this.getAccountType(),
        case_id: this.$route.params.hashId,
        env: process.env.VUE_APP_KREDITZ_ENV,
      };

      Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.set(key, value);
      });

      return url.toString();
    },
  },

  mounted() {
    this.startPolling();
  },

  methods: {
    ...mapActions({
      getApplication: 'application/loadLoanApplication',
    }),

    changePage() {
      DataLayer.solvencyCheckFinished();

      this.$router.push({ name: 'changePage' }).catch(() => {});
    },

    getAccountType() {
      if (this.isBusinessLoan) {
        return this.isSoleTrader ? this.selectedAccountType : 'business';
      }

      return 'private';
    },

    startPolling() {
      const sleep = time => new Promise(resolve => setTimeout(resolve, time));
      const poll = async (time) => {
        await this.getApplication();
        if (this.initialData.currentPage && this.initialData.currentPage !== 'kreditz') {
          this.changePage();
          return;
        }
        await sleep(time);
        await poll(time);
      };
      poll(1000);
    },
  },
};
</script>

<style lang="scss">
.cw-kreditz {
  &__wrapper {
    padding: 16px 0;
    border-radius: 4px;
    background: rgb(245, 248, 249);

    .cw-kreditz__accountSelection {
      display: grid;
      place-content: center;
      height: 320px;
      padding: 16px;

      p {
        max-width: 480px;
      }

      .v-btn-toggle {
        button.v-btn[id] {
          border-color: currentColor !important;
          color: currentColor;
        }
      }
    }
  }

  &__iframe {
    border: none;
    height: 600px;
    width: 100%;
  }

}
</style>
