var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"cw-kreditz"},[_c('v-row',{staticClass:"cw-page__title",attrs:{"align":"start"}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mt-1"},[_vm._v(" person_outline ")])],1),_c('v-col',{staticClass:"pl-0"},[_c('h1',{staticClass:"text-h5"},[_c('translate',[_vm._v(" Verification of solvency ")])],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-1"},[_c('p',[_c('translate',[_vm._v(" In order to provide you with a personal and fast loan offer, we use Kreditz to verify your solvency. The service enables responsible lending and usually takes less than 30 seconds. ")]),_c('a',{staticClass:"ml-1",attrs:{"id":"cw-kreditz--info","href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$bus.$emit('OPEN_CONFIRMATION_DIALOG', {
              component: 'DialogKreditz',
            });
            _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("More information")])],1)],1),(!_vm.mobile)?_c('v-alert',{attrs:{"border":"left","type":"warning","dense":"","text":""}},[_c('translate',[_vm._v(" Antivirus protection may cause a problem when using Kreditz that prevents the connection to Kreditz. You can temporarily turn off the antivirus protection you are using to complete the Kreditz check. ")])],1):_vm._e(),(_vm.isBusinessLoan && !_vm.isSoleTrader)?_c('v-alert',{attrs:{"border":"left","type":"info","dense":"","text":""}},[_c('translate',[_vm._v(" In order to log in, please use your company's bank account and bank ID credentials. If you have any questions, you can contact our customer support. ")])],1):_vm._e(),(!_vm.isBusinessLoan)?_c('v-alert',{attrs:{"border":"left","type":"info","dense":"","text":""}},[_c('translate',[_vm._v(" Please select your main bank and identify yourself with your online banking ID. Please note, that the loan will also be transferred to this specific bank. ")])],1):_vm._e(),_c('div',{staticClass:"cw-kreditz__wrapper"},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[(
              !_vm.isBusinessLoan
                || (_vm.isBusinessLoan && !_vm.isSoleTrader)
                || (_vm.isBusinessLoan && _vm.isSoleTrader && _vm.selectedAccountType)
            )?_c('iframe',{key:_vm.selectedAccountType,staticClass:"cw-kreditz__iframe",attrs:{"id":"cw-kreditz__iframe","src":_vm.url}}):_c('div',{staticClass:"cw-kreditz__accountSelection"},[_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
                phoneNumber: '06000&nbsp;55000',
              }),expression:"{\n                phoneNumber: '06000&nbsp;55000',\n              }"}],staticClass:"text-title mb-4",attrs:{"render-html":"true"}},[_vm._v(" Please select one of the following bank account types for the required solvency check of your company. If you have any questions, please contact our customer support at number %{ phoneNumber }. ")]),_c('v-btn-toggle',{staticClass:"transparent primary--text mb-8",attrs:{"color":"primary","active-class":"primary white--text","dense":""},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"id":"cw-kreditz__account-type--private","value":"private"},on:{"click":function($event){return _vm.$eventLogger.clickEvent($event)}}},[_c('translate',[_vm._v(" Personal ")])],1),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"id":"cw-kreditz__account-type--business","value":"business"},on:{"click":function($event){return _vm.$eventLogger.clickEvent($event)}}},[_c('translate',[_vm._v(" Business ")])],1)],1),_c('v-btn',{attrs:{"color":"grey darken-4","disabled":!_vm.accountType,"dark":!!_vm.accountType},on:{"click":function($event){_vm.selectedAccountType = _vm.accountType}}},[_c('translate',[_vm._v("Start the check")])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }